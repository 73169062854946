
import { Component, Vue } from 'vue-property-decorator'
import ImportData from '@/components/importTemplateData/Index.vue'
import TypeDialog from '@/components/typeDialog/Index.vue'
import { TypeInfo } from '../../types/common'
import { Info } from '@/types/potion'

@Component({
  name: 'PotionList',
  components: { ImportData, TypeDialog }
})
export default class extends Vue {
  private typeList: TypeInfo[]=[]
  private searchInfo = {
    medicineName: '',
    preventionObject: '',
    medicineType: '',
    medicinePart: '',
    recommendStatus: '',
    tradeName: ''
  }

  private loading = false
  private list: Array<Info> = []
  private page=1
  private size = 10
  private total=0

  private isShowImport = false
  private typeShow = false

  created () {
    this.getTypeList()
    this.getTableList()
  }

  getTableList () {
    this.loading = true
    this.$axios.get(this.$apis.potion.selectMedicineList, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.list = res.list || []
      this.total = res.total || 0
      this.loading = false
    }).finally(() => {
      this.loading = false
    })
  }

  // 获取药剂类别
  getTypeList () {
    this.$axios.get<{total: number; list: TypeInfo[]}>(this.$apis.common.thinktankTypeList, {
      dicType: 'medicineType'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  onSearch () {
    this.page = 1
    this.getTableList()
  }

  // 药剂推荐状态变更
  statusChange (row: Info) {
    console.log(row)
    this.$axios.post(this.$apis.potion.updateMedicineRecommendStatus, {
      medicineId: row.medicineId
    }).then(() => {
      this.$message.success(row.recommendStatus === '0' ? '药剂推荐开启成功' : '药剂推荐关闭成功')
      this.getTableList()
    }).catch(() => {
      this.$message.success(row.recommendStatus === '0' ? '药剂推荐开启失败' : '药剂推荐关闭失败')
      this.$set(row, 'recommendStatus', row.recommendStatus === '0' ? '1' : '0')
    })
  }

  onAdd () {
    this.$router.push({ name: 'potionAdd' })
  }

  onDetail (id: string) {
    this.$router.push({ name: 'potionDetail', params: { id } })
  }

  onEdit (id: string) {
    this.$router.push({ name: 'potionUpdate', params: { id } })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.potion.deleteMedicine, {
        medicineId: id
      }).then(() => {
        this.$message.success('删除成功')
        this.getTableList()
      })
    })
  }
}
